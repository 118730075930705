export const MenuItems = [
  {
    title: "Our Support",
    path: "/support",
    cName: "dropdown-link",
  },
  {
    title: "Laboratory",
    path: "/lab",
    cName: "dropdown-link",
  },
  {
    title: "Social Life",
    path: "/socialLife",
    cName: "dropdown-link",
  },
];
